import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import { ParamProps } from '../ParamProps.';
import styles from './BooleanParam.module.css';

const BooleanParam = (props: ParamProps) => {
  const [enabled, setEnabled] = useState<boolean>(props.default);

  useEffect(() => {
    props.setValue(enabled);
  }, [enabled]);
  return (
    <div className={styles.CheckIput}>
      <Form.Check
        checked={enabled}
        id={`boolean_prop_${props.name}`}
        onChange={(event: any) => setEnabled(event.target.checked)}
        label={'Enabled'}
      ></Form.Check>
    </div>
  );
};

export default BooleanParam;
