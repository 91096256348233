import React, { useEffect, useState } from 'react';
import DateTimeParam from '../DateTime/DataTimeParam';
import styles from './IntervalParam.module.css';
import { ParamProps } from '../ParamProps.';

const IntervalParam = ({ setValue, value }: ParamProps) => {
  const [interval, setInterval] = useState([undefined, undefined]);

  useEffect(() => {
    if (interval[0] && interval[1]) {
      setValue(interval);
    }
  }, [interval, value]);

  return (
    <div className={styles.IntervalContainer}>
      <div className={styles.DateSelector}>
        <DateTimeParam
          value={value ? value[0] : undefined}
          setValue={(event: any) => setInterval([event, interval[1]])}
        ></DateTimeParam>
      </div>
      <div className={styles.DateSelector}>
        <DateTimeParam
          value={value ? value[1] : undefined}
          setValue={(event: any) => setInterval([interval[0], event])}
        ></DateTimeParam>
      </div>
    </div>
  );
};

export default IntervalParam;
