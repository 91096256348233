import styles from './Toasts.module.css';
import { useEffect, useState } from 'react';
import { Toast as ToastElem } from 'react-bootstrap';
import { Toast, TOAST_COLORS } from './Toast.model';

export interface ToastProps {
  toast: Toast | undefined;
}
function Toasts({ toast }: ToastProps) {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const closeToast = (toast: Toast) => {
    setToasts(toasts.filter((t: Toast) => t.message.toLowerCase() === toast.message.toLowerCase()));
  };

  useEffect(() => {
    if (toast) {
      setToasts(
        [...toasts, toast].filter(
          (t: Toast, idx: number, tsts: Toast[]) =>
            tsts.findIndex((_t: Toast) => _t.message.toLowerCase() === t.message.toLowerCase()) ===
            idx,
        ),
      );

      setTimeout(() => {
        closeToast(toast);
      }, 3000);
    }
  }, [toast]);

  return (
    <div className={styles.ToastContainer}>
      {toasts.map((t: Toast, idx: number) => (
        <ToastElem key={`toast_${idx}`} bg={TOAST_COLORS[t.type]}>
          <ToastElem.Body>{t.message}</ToastElem.Body>
        </ToastElem>
      ))}
    </div>
  );
}

export default Toasts;
