export enum ToastType {
  INFO = 'Info',
  WARNING = 'Warning',
  ERROR = 'Error',
}

export const TOAST_COLORS = {
  [ToastType.INFO]: 'light',
  [ToastType.WARNING]: 'warning',
  [ToastType.ERROR]: 'danger',
};
export interface Toast {
  message: string;
  type: ToastType;
}
