import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import { ParamProps } from '../ParamProps.';

interface SelectParamProps extends ParamProps {
  options: string[];
}

const SelectParam = (props: SelectParamProps) => {
  const [value, setValue] = useState<string>(props.default);

  useEffect(() => {
    props.setValue(value);
  }, [value]);

  return (
    <Form.Select onChange={(event: any) => setValue(event.target.value)}>
      {props.options.map((o) => (
        <option key={`option_${o}`} value={o}>
          {o}
        </option>
      ))}
    </Form.Select>
  );
};

export default SelectParam;
