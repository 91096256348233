import React, { useEffect } from 'react';
import 'react-datetime/css/react-datetime.css';
import styles from './GeometryParam.module.css';
import { GeometryParamProps } from './GeometryParamProps';
import { useMapStore } from '../../../../stores/map.store';
import { Feature, Polygon } from 'geojson';
import { Alert, Button } from 'react-bootstrap';
import { useUiStore } from '../../../../stores/ui.store';
import { ToastType } from '../../../toasts/Toast.model';

const GeometryParam = ({ setValue, areaLimit }: GeometryParamProps) => {
  const { setDrawMode, features, deleteFeatures } = useMapStore();
  const { setToast } = useUiStore();

  useEffect(() => {
    setDrawMode(true);
    return () => {
      setDrawMode(false);
    };
  }, []);

  useEffect(() => {
    if (features.length > 0) {
      if (features[0]?.properties?.area && features[0].properties.area <= areaLimit) {
        setValue(features[0].geometry as Polygon);
      } else {
        setToast({
          message: `Available area is limited to ${areaLimit} ha`,
          type: ToastType.ERROR,
        });
        deleteFeatures([features[0]]);
      }
    }
  }, [features]);

  return (
    <div className={styles.GeomParam}>
      {features.length === 0 ? (
        <Alert key='info' variant='info' className={styles.Alert}>
          Draw your area of interest on the map (max {areaLimit} ha)
        </Alert>
      ) : (
        ''
      )}
      <div className={styles.FeatureList}>
        {features.map((f: Feature, idx: number) => (
          <div className={styles.Feature} key={f.id}>
            <span>
              {f.geometry.type} {idx + 1}
            </span>
            <div className={styles.FeatureActions}>
              <span>{f.properties?.area || 0} ha</span>
              <Button onClick={() => deleteFeatures([f])} variant='danger' size='sm'>
                <i className='bi bi-trash3-fill'></i>
              </Button>
            </div>
          </div>
        ))}
      </div>
      <div></div>
    </div>
  );
};

export default GeometryParam;
