import { OpenEOJob, OpenEOJobLog } from '../../interfaces/OpenEOProcess';
import styles from './Logs.module.css';
import React, { useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useUiStore } from '../../stores/ui.store';

export interface LogProps {
  logs: OpenEOJobLog[];
  job: OpenEOJob | undefined;
}
function Logs({ logs, job }: LogProps) {
  const levels = ['debug', 'info', 'warning', 'error'];
  const [filter, setFilter] = useState<string>('');
  const [selectedIds, setSelectedIds] = useState<string[]>([]);
  const [activeLevels, setActiveLevels] = useState<string[]>(levels);
  const { setLogs } = useUiStore();

  const toggleLevel = (level: string) => {
    if (activeLevels.includes(level)) {
      setActiveLevels(activeLevels.filter((al) => al !== level));
    } else {
      setActiveLevels([...activeLevels, level]);
    }
  };

  const closeLogs = () => {
    setLogs();
  };

  return (
    <div className={styles.LogContainer}>
      <div className={styles.Title}>
        <h3>Logs - {job?.title}</h3>
        <Button variant='primary' onClick={closeLogs}>
          Close
        </Button>
      </div>
      <div className={styles.Actions}>
        <Form.Control
          value={filter}
          onChange={(event: any) => setFilter(event.target.value)}
        ></Form.Control>
        <div className={styles.LevelFilter}>
          {levels.map((l, idx) => (
            <div
              key={`level_${idx}`}
              className={`${styles.Status} ${
                styles[!activeLevels.includes(l) ? l + '_inactive' : l]
              }`}
              onClick={() => toggleLevel(l)}
            >
              {l}
            </div>
          ))}
        </div>
      </div>
      <div className={styles.LogList}>
        {logs
          .filter(
            (l: OpenEOJobLog) =>
              (filter === '' || l.message.toLowerCase().includes(filter.toLowerCase())) &&
              activeLevels.includes(l.level.toLowerCase()),
          )
          .map((l: OpenEOJobLog, idx: number) => (
            <div className={styles.Log} key={idx}>
              <div className={styles.Header}>
                <div className={styles.Date}>{l.time}</div>
                <div className={`${styles.Status} ${styles[l.level]}`}>{l.level}</div>
              </div>
              <div className={styles.Message}>
                {l.message}
                {/*<div>*/}
                {/*  {selectedIds.includes(l.message) ? l.message : `${l.message.substring(0, 50)}...`}*/}
                {/*</div>*/}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default Logs;
