import { OpenEONameSpace, OpenEOProcess, OpenEOProcessParam } from '../interfaces/OpenEOProcess';
import { OpenEOCollection } from '../interfaces/OpenEOCollection';

const OPENEO_BASE = 'https://openeo.vito.be/openeo/1.0';

export const getCollections = async (): Promise<OpenEOCollection[]> => {
  const response = await fetch(`${OPENEO_BASE}/collections`);

  if (!response.ok) {
    throw new Error(`Could not retrieve collections: ${await response.text()}`);
  }

  const collections: OpenEOCollection[] = (await response.json()).collections || [];
  return collections
    .map((c: OpenEOCollection) => ({
      ...c,
      title: c.title || c.id,
    }))
    .sort((c1: OpenEOCollection, c2: OpenEOCollection) => c1.title.localeCompare(c2.title));
};
export const getServicesFromNS = async (namespace: OpenEONameSpace): Promise<OpenEOProcess[]> => {
  const response = await fetch(`${OPENEO_BASE}/processes/${namespace.name}`);

  if (!response.ok) {
    throw new Error(
      `Could not retrieve services from namespace ${namespace.name}: ${await response.text()}`,
    );
  }

  const processes: OpenEOProcess[] = (await response.json()).processes || [];
  return processes
    .filter((p: OpenEOProcess) => namespace.services.find((s) => s.id === p.id))
    .map((p: OpenEOProcess) => ({
      ...p,
      namespace: namespace.name,
      label: namespace.services.find((s) => s.id === p.id)?.label || p.id,
      areaLimit: namespace.services.find((s) => s.id === p.id)?.areaLimit || 0,
      parameters: p.parameters.map((param: OpenEOProcessParam) => ({
        ...param,
        value: param.default,
      })),
    }));
};
