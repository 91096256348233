import { create } from 'zustand';
import { Feature } from 'geojson';
import { computed } from 'zustand-computed';
import area from '@turf/area';
import { OpenEOJobResultSummary } from '../interfaces/OpenEOProcess';

interface MapStore {
  drawModeActive: boolean;
  features: Feature[];
  setDrawMode: (active: boolean) => void;
  addFeatures: (features: Feature[]) => void;
  deleteFeatures: (features: Feature[]) => void;
  updateFeatures: (features: Feature[]) => void;
  jobResult: OpenEOJobResultSummary | undefined;
  showJobResult: (result: OpenEOJobResultSummary) => void;
  hideJobResult: () => void;
}
export interface ComputedMapStore {
  enableDraw: boolean;
}

export const useMapStore = create<MapStore>()(
  computed(
    (set) => ({
      features: [],
      drawModeActive: false,
      enableDraw: false,
      setDrawMode: (active: boolean) => set((state) => ({ drawModeActive: active })),
      addFeatures: (features: Feature[]) =>
        set((state) => ({
          features: [
            ...state.features,
            ...features.map((f: Feature) => ({
              ...f,
              properties: {
                ...f.properties,
                area: +(area(f) / 10000).toFixed(2),
              },
            })),
          ],
        })),
      deleteFeatures: (features: Feature[]) =>
        set((state) => {
          const ids = features.map((f: Feature) => f.id);
          return {
            features: state.features.filter((f: Feature) => !ids.includes(f.id)),
          };
        }),
      updateFeatures: (features: Feature[]) =>
        set((state) => ({
          features: state.features.map((sf: Feature) => {
            const hit = features.find((f: Feature) => f.id === sf.id);

            if (hit) {
              return {
                ...hit,
                properties: {
                  ...hit.properties,
                  area: +(area(hit) / 10000).toFixed(2),
                },
              };
            } else {
              return sf;
            }
          }),
        })),
      jobResult: undefined,
      showJobResult: (result: OpenEOJobResultSummary) =>
        set(() => ({
          jobResult: result,
        })),
      hideJobResult: () =>
        set(() => ({
          jobResult: undefined,
        })),
    }),
    (state: MapStore): ComputedMapStore => ({
      enableDraw: state.drawModeActive && state.features.length === 0,
    }),
  ),
);
