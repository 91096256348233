import { create } from 'zustand';
import { Toast } from '../components/toasts/Toast.model';
import { computed } from 'zustand-computed';
import { OpenEOJob, OpenEOJobLog } from '../interfaces/OpenEOProcess';

const LOADING_MESSAGE: any = {
  execute: 'Launching job',
  remove_job: 'Removing job',
  refresh_jobs: 'Refreshing jobs',
  load_app: 'Loading application',
  login: 'Signing in',
  load_result: 'Loading job results',
  download_result: 'Downloading job results',
  load_logs: 'Retrieving job logs',
  load_tiles: 'Loading job results on map',
};

interface UiStore {
  toast: Toast | undefined;
  setToast: (toast: Toast) => void;
  loadingIds: string[];
  jobInfo: OpenEOJob | undefined;
  logs: OpenEOJobLog[];
  startLoading: (id: string) => void;
  stopLoading: (id: string) => void;
  setLogs: (job?: OpenEOJob, logs?: OpenEOJobLog[]) => void;
}

interface ComputedUiStore {
  loading: boolean;
  loadingMessage: string;
}

export const useUiStore = create<UiStore>()(
  computed(
    (set) => ({
      toast: undefined,
      loadingIds: [],
      logs: [],
      jobInfo: undefined,
      setToast: (toast: Toast) => set((state) => ({ toast })),
      setLogs: (job?: OpenEOJob, logs?: OpenEOJobLog[]) =>
        set((state) => ({ logs: logs || [], jobInfo: job })),
      startLoading: (id: string) => set((state) => ({ loadingIds: [...state.loadingIds, id] })),
      stopLoading: (id: string) =>
        set((state) => ({ loadingIds: state.loadingIds.filter((i) => i !== id) })),
    }),
    (state: UiStore): ComputedUiStore => ({
      loading: state.loadingIds.length > 0,
      loadingMessage: state.loadingIds
        .map((id: string) => (LOADING_MESSAGE[id] as string) || id)
        .join(', '),
    }),
  ),
);
