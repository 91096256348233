import { create } from 'zustand';

const LOCAL_API_KEY = 'AI4FOOD_API_KEY';

interface UserStore {
  apiKey: string | null;
  setAPIKey: (apiKey: string | null) => void;
}
export const useUserStore = create<UserStore>((set) => ({
  apiKey: localStorage.getItem(LOCAL_API_KEY),
  setAPIKey: (apiKey: string | null) =>
    set((state) => {
      if (apiKey) {
        localStorage.setItem(LOCAL_API_KEY, apiKey);
      } else {
        localStorage.removeItem(LOCAL_API_KEY);
      }
      return {
        apiKey,
      };
    }),
}));
