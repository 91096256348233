import {
  OpenEOJob,
  OpenEOJobLog,
  OpenEOJobResult,
  OpenEOJobResultSummary,
  OpenEONameSpace,
  OpenEOProcess,
  OpenEOProcessParam,
} from '../interfaces/OpenEOProcess';
import { APIProcess } from '../interfaces/APIProcess';
import { getServicesFromNS } from './OpenEO';

import moment from 'moment';
import { transformExtent } from 'ol/proj';
import { setupGeoTiff } from './Geotiff';

const API_HOST = process.env.REACT_APP_API;

setupGeoTiff();

export const getProcesses = async (apiKey: string): Promise<OpenEOProcess[]> => {
  const namespaces: OpenEONameSpace[] = parseUserServices(await getProcessesFromAPI(apiKey));
  return Promise.all(namespaces.map((ns: OpenEONameSpace) => getServicesFromNS(ns))).then(
    (result) => ([] as OpenEOProcess[]).concat(...result),
  );
};

export const getJobs = async (apiKey: string): Promise<OpenEOJob[]> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}`);

  if (!response.ok) {
    throw new Error(`Could not retrieve jobs: ${await response.text()}`);
  }
  return (await response.json())
    .sort((j1: OpenEOJob, j2: OpenEOJob) =>
      moment(j1.created).isAfter(moment(j2.created)) ? -1 : 1,
    )
    .map((j: OpenEOJob) => ({
      ...j,
      created: convertToDate(j.created),
      updated: convertToDate(j.updated),
    }));
};
export const deleteJob = async (apiKey: string, id: number): Promise<void> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}/${id}`, {
    method: 'DELETE',
  });

  if (!response.ok) {
    throw new Error(`Could not delete job ${id}: ${await response.text()}`);
  }
};

export const executeProcess = async (
  apiKey: string,
  service: string,
  namespace: string,
  params: OpenEOProcessParam[],
  title?: string,
): Promise<string> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      title,
      service,
      namespace,
      params,
    }),
  });

  if (response.status !== 201) {
    throw new Error(`Could not execute process: ${await response.text()}`);
  }
  return (await response.json()).jobId;
};

const parseUserServices = (processes: APIProcess[]): OpenEONameSpace[] => {
  const result: OpenEONameSpace[] = [];
  processes.forEach((p: APIProcess) => {
    const hit = result.find((r) => r.name === p.namespace);
    const service = {
      id: p.service,
      label: p.label,
      areaLimit: p.area,
    };
    if (hit) {
      hit.services.push(service);
    } else {
      result.push({
        name: p.namespace,
        services: [service],
      });
    }
  });
  return result;
};

export const keyExists = async (apiKey: string): Promise<boolean> => {
  const response = await fetch(`${API_HOST}/apikeys/${apiKey}/exists`);

  if (!response.ok) {
    console.error(`Could check if key ${apiKey} exists: ${await response.text()}`);
  }

  return response.ok;
};
const getProcessesFromAPI = async (apiKey: string): Promise<APIProcess[]> => {
  const response = await fetch(`${API_HOST}/apikeys/${apiKey}`);

  if (!response.ok) {
    throw new Error(`Could not retrieve processes from API: ${await response.text()}`);
  }

  return (await response.json()).services || [];
};

export const getJobResult = async (
  apiKey: string,
  jobId: number,
): Promise<OpenEOJobResultSummary> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}/${jobId}/results`);

  if (!response.ok) {
    console.error(
      `Could not get results for job ${jobId} for  ${apiKey}: ${await response.text()}`,
    );
    throw new Error(`Could not retrieve results for ${jobId}: ${await response.text()}`);
  }

  const results = await response.json();
  return {
    jobId,
    results: results.map((r: OpenEOJobResult) => ({
      ...r,
      bbox: transformExtent(r.bbox, `EPSG:${r.epsg}`, 'EPSG:3857'),
    })),
  };
};
export const downloadJobResults = async (
  apiKey: string,
  jobId: number,
  filename?: string,
): Promise<void> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}/${jobId}/download`);

  if (!response.ok) {
    console.error(
      `Could not download results for job ${jobId} for  ${apiKey}: ${await response.text()}`,
    );
    throw new Error(`Could not download results for ${jobId}: ${await response.text()}`);
  }

  downloadBlob(await response.blob(), filename);
};

export const downloadBlob = (blob: Blob, filename?: string) => {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename || 'result.zip';
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
};

export const downloadJobLogs = async (
  apiKey: string,
  jobId: number,
  filename?: string,
): Promise<OpenEOJobLog[]> => {
  const response = await fetch(`${API_HOST}/jobs/${apiKey}/${jobId}/logs`);

  if (!response.ok) {
    console.error(
      `Could not download results for job ${jobId} for  ${apiKey}: ${await response.text()}`,
    );
    throw new Error(`Could not download results for ${jobId}: ${await response.text()}`);
  }
  const logs = await response.json();
  return logs.map((l: OpenEOJobLog) => ({
    ...l,
    time: convertToDate(l.time, true),
  }));
};

const convertToDate = (date: string, includeTime: boolean = false) =>
  moment(date).calendar({
    sameElse: includeTime ? 'DD MMM YYYY HH:mm:ss.SSS' : 'DD MMM YYYY',
  });
