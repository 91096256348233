import { OpenEOJob } from '../../interfaces/OpenEOProcess';
import styles from './Jobs.module.css';
import { Button, ButtonGroup } from 'react-bootstrap';
import React from 'react';
import { useUiStore } from '../../stores/ui.store';
import { downloadJobLogs, downloadJobResults, getJobResult } from '../../utils/Api';
import { useUserStore } from '../../stores/user.store';
import { useMapStore } from '../../stores/map.store';
import { ToastType } from '../toasts/Toast.model';

interface JobsProps {
  jobs: OpenEOJob[];
  removeJob: (jobId: number) => void;
}
function Jobs({ jobs, removeJob }: JobsProps) {
  const { toast, setToast, loading, startLoading, stopLoading, setLogs } = useUiStore();
  const loadingId = 'load_result';
  const downloadLoadingId = 'download_result';
  const logsLoadingId = 'load_logs';
  const { apiKey } = useUserStore();
  const { showJobResult, hideJobResult, jobResult } = useMapStore();

  const toggleJobResult = async (job: OpenEOJob) => {
    if (jobResult?.jobId === job.id) {
      hideJobResult();
    } else if (apiKey) {
      startLoading(loadingId);
      try {
        const result = await getJobResult(apiKey, job.id);
        showJobResult(result);
      } catch (e) {
        console.error('Could not visualize result', e);
        setToast({
          message: `Could not visualize result for job ${job.title}`,
          type: ToastType.ERROR,
        });
      }
      stopLoading(loadingId);
    }
  };

  const downloadResult = async (job: OpenEOJob) => {
    if (apiKey) {
      startLoading(downloadLoadingId);
      try {
        await downloadJobResults(apiKey, job.id, `${job.title.replaceAll(' ', '_')}.zip`);
      } catch (e) {
        console.error(`Could not download the results for job ${job.id}`, e);
        setToast({
          message: `Could not download the results for job ${job.title}`,
          type: ToastType.ERROR,
        });
      }
      stopLoading(downloadLoadingId);
    }
  };

  const getJobLogs = async (job: OpenEOJob) => {
    if (apiKey) {
      startLoading(logsLoadingId);
      try {
        const result = await downloadJobLogs(apiKey, job.id);
        setLogs(job, result);
      } catch (e) {
        console.error(`Could not get logs for job ${job.id}`, e);
        setToast({
          message: `Could not get logs for job ${job.title}`,
          type: ToastType.ERROR,
        });
        setLogs();
      }
      stopLoading(logsLoadingId);
    }
  };

  return (
    <div className={`Container`}>
      <div className={styles.JobContainer}>
        <h3>Jobs</h3>
        {jobs.map((j: OpenEOJob) => (
          <>
            <div className={styles.Title}>
              <span className={styles.Date}>{j.created}</span>
              <span>{j.title}</span>
            </div>
            <span className={`${styles.Status} ${styles[j.status]}`}>{j.status}</span>
            <div className={styles.Actions}>
              <ButtonGroup>
                <Button
                  onClick={() => removeJob(j.id)}
                  variant='light'
                  size='sm'
                  title='Remove job'
                >
                  <i className='bi bi-trash3-fill'></i>
                </Button>
                {j.status === 'finished' ? (
                  <>
                    <Button
                      onClick={() => toggleJobResult(j)}
                      variant='light'
                      size='sm'
                      title={jobResult?.jobId === j.id ? 'Hide job results' : 'Show job results'}
                    >
                      {jobResult?.jobId === j.id ? (
                        <i className='bi bi-eye-slash-fill'></i>
                      ) : (
                        <i className='bi bi-eye-fill'></i>
                      )}
                    </Button>
                    <Button
                      variant='light'
                      size='sm'
                      onClick={() => downloadResult(j)}
                      title='Download job results'
                    >
                      <i className='bi bi-cloud-download'></i>
                    </Button>
                  </>
                ) : (
                  <></>
                )}
                <Button
                  variant='light'
                  size='sm'
                  onClick={() => getJobLogs(j)}
                  title='Retrieve job logs'
                >
                  <i className='bi bi-bug'></i>
                </Button>
              </ButtonGroup>
            </div>
          </>
        ))}
      </div>
    </div>
  );
}

export default Jobs;
