import React from 'react';
import Datetime from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { ParamProps } from '../ParamProps.';

const DateTimeParam = ({ setValue, value }: ParamProps) => {
  return (
    <Datetime
      value={value}
      onChange={(event: any) => setValue(event.format('YYYY-MM-DD'))}
      timeFormat={false}
      dateFormat={'YYYY-MM-DD'}
    />
  );
};

export default DateTimeParam;
