import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import { ParamProps } from '../ParamProps.';
import { Button, Form } from 'react-bootstrap';
import { OpenEOCollection } from '../../../../interfaces/OpenEOCollection';
import styles from './CollectionParam.module.css';
import { Highlighter, Typeahead } from 'react-bootstrap-typeahead';

interface CollectionParamProps extends ParamProps {
  collections: OpenEOCollection[];
  maxCollections?: number;
}

const renderCollection = (collection: OpenEOCollection) => (
  <option value={collection.id} key={`collection_param_${collection.id}`}>
    {collection.title}
  </option>
);

const CollectionParam = ({
  collections,
  maxCollections,
  value,
  setValue,
}: CollectionParamProps) => {
  const [selected, setSelected] = useState<OpenEOCollection[]>([]);

  const selectCollection = (cols: any[]) => {
    const ids = cols.map((s) => s.id);
    const match = collections.filter((c: OpenEOCollection) => ids.includes(c.id));
    if (match) {
      setSelected(match || selected || []);
    }
  };

  const removeCollection = (id: string) => {
    setSelected((selected || []).filter((c: OpenEOCollection) => c.id !== id));
  };

  useEffect(() => {
    if (selected && selected.length > 0) {
      setValue(selected[0].id);
    } else {
      setValue(undefined);
    }
  }, [selected]);
  return (
    <div>
      <div className={styles.CollectionParam}>
        <Form.Group>
          <Typeahead
            id='basic-typeahead-single'
            onChange={selectCollection}
            options={collections
              .filter(
                (c: OpenEOCollection) =>
                  !(selected || []).find((sc: OpenEOCollection) => sc.id == c.id),
              )
              .map((c: OpenEOCollection) => ({
                label: c.title || c.id,
                description: c.description,
                id: c.id,
              }))}
            placeholder='Choose a collection'
            selected={selected.map((c) => ({
              label: c.title || c.id,
              description: c.description,
              id: c.id,
            }))}
            positionFixed={true}
            labelKey={'label'}
            renderMenuItemChildren={(option: any, { text }) => (
              <>
                <Highlighter search={text}>{option.label}</Highlighter>
                <div className={styles.SubText}>{option.description}</div>
              </>
            )}
          />
        </Form.Group>
      </div>
      <div className={styles.CollectionList}>
        {selected?.map((c: OpenEOCollection) => (
          <div key={c.id} className={styles.CollectionItem}>
            <div className={styles.CollectionInfo}>
              <div className={styles.CollectionTitle}>{c.title}</div>
              <div className={styles.CollectionDescription}>{c.description}</div>
            </div>
            <div className={styles.CollectionActions}>
              <Button onClick={() => removeCollection(c.id)}>
                <i className='bi bi-trash3-fill'></i>
              </Button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CollectionParam;
