import React, { useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import 'react-datetime/css/react-datetime.css';
import { ParamProps } from '../ParamProps.';

const StringParam = (props: ParamProps) => {
  const [value, setValue] = useState<string>(props.default);

  useEffect(() => {
    props.setValue(value);
  }, [value]);

  return (
    <Form.Control
      value={value}
      onChange={(event: any) => setValue(event.target.value)}
    ></Form.Control>
  );
};

export default StringParam;
