import React, { useEffect, useState } from 'react';
import './App.css';
import { deleteJob, getJobs, getProcesses, keyExists } from './utils/Api';
import { OpenEOJob, OpenEOProcess } from './interfaces/OpenEOProcess';
import Map from './components/map';
import ProcessBuilder from './components/processbuilder';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-bootstrap-typeahead/css/Typeahead.bs5.css';
import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import { OpenEOCollection } from './interfaces/OpenEOCollection';
import { getCollections } from './utils/OpenEO';
import { useUiStore } from './stores/ui.store';
import Toasts from './components/toasts';
import { ToastType } from './components/toasts/Toast.model';
import { Button, Form, ProgressBar } from 'react-bootstrap';
import Jobs from './components/jobs';
import { useUserStore } from './stores/user.store';
import Logs from './components/logs';

enum Tab {
  SERVICES,
  JOBS,
}

function App() {
  const [processes, setProcesses] = useState<OpenEOProcess[]>([]);
  const [collections, setCollections] = useState<OpenEOCollection[]>([]);
  const [jobs, setJobs] = useState<OpenEOJob[]>();
  const [jobRefresh, setJobRefresh] = useState<boolean>(true);
  const [jobRefreshProm, setJobRefreshProm] = useState<any>();
  const [activeTab, setActiveTab] = useState<Tab>(Tab.SERVICES);
  const { toast, setToast, loading, startLoading, stopLoading, loadingMessage, logs, jobInfo } =
    useUiStore();
  const { apiKey, setAPIKey } = useUserStore();

  const removeJob = (id: number) => {
    const loadingId = 'remove_job';
    startLoading(loadingId);
    deleteJob(apiKey!, id)
      .catch((e) => {
        console.error(`Could not delete job ${id}`, e);
        setToast({
          type: ToastType.ERROR,
          message: 'Unable to remove openEO job',
        });
      })
      .then(() => refreshJobs())
      .finally(() => {
        stopLoading(loadingId);
      });
  };

  const refreshJobs = (): Promise<void> =>
    getJobs(apiKey!)
      .then((jobs: OpenEOJob[]) => setJobs(jobs))
      .then(() => {
        const loadingId = 'refresh_jobs';
        if (jobRefresh) {
          if (jobRefreshProm) {
            clearTimeout(jobRefreshProm);
          }
          setJobRefreshProm(
            setTimeout(() => {
              startLoading(loadingId);
              refreshJobs().then(() => {
                stopLoading(loadingId);
              });
            }, 15000),
          );
        }
      })
      .catch((e) => {
        console.error('Could not retrieve jobs', e);
        setToast({
          type: ToastType.ERROR,
          message: 'Unable to retrieve openEO jobs',
        });
        setJobs([]);
      });

  useEffect(() => {
    if (apiKey) {
      const loadingId = 'load_app';
      startLoading(loadingId);
      Promise.all([
        getProcesses(apiKey!)
          .then((ps: OpenEOProcess[]) => setProcesses(ps))
          .catch((e) => {
            console.error('Could not retrieve processes', e);
            setToast({
              type: ToastType.ERROR,
              message: 'Unable to retrieve openEO processes',
            });
            setProcesses([]);
          }),
        getCollections()
          .then((cs: OpenEOCollection[]) => setCollections(cs))
          .catch((e) => {
            console.error('Could not retrieve collections', e);
            setToast({
              type: ToastType.ERROR,
              message: 'Unable to retrieve openEO collections',
            });
            setCollections([]);
          }),
        refreshJobs(),
      ]).then(() => {
        stopLoading(loadingId);
      });
    }
  }, [apiKey]);

  useEffect(() => {
    if (activeTab === Tab.JOBS) {
      refreshJobs().then();
    }
  }, [activeTab]);

  const generatePanels = () => (
    <div className='Panel'>
      <div className='PanelContent'>
        {activeTab === Tab.SERVICES ? (
          <ProcessBuilder
            apiKey={apiKey!}
            processes={processes}
            collections={collections}
            serviceExecuted={() => {
              setActiveTab(Tab.JOBS);
            }}
          ></ProcessBuilder>
        ) : (
          <></>
        )}
        {activeTab === Tab.JOBS ? <Jobs jobs={jobs || []} removeJob={removeJob}></Jobs> : <></>}
      </div>
      <div className='PanelTabs'>
        <div
          className={`PanelTab ${activeTab === Tab.SERVICES ? 'active' : ''}`}
          onClick={() => setActiveTab(Tab.SERVICES)}
        >
          <i className='bi bi-play-fill'></i>
        </div>
        <div
          className={`PanelTab ${activeTab === Tab.JOBS ? 'active' : ''} `}
          onClick={() => setActiveTab(Tab.JOBS)}
        >
          <i className='bi bi-clipboard-pulse'></i>
          {jobs && jobs.length > 0 ? <span className='PanelCount'>{jobs.length}</span> : <></>}
        </div>
        <div className='PanelTab' onClick={() => setAPIKey(null)}>
          <i className='bi bi-box-arrow-right'></i>
        </div>
      </div>
    </div>
  );

  const login = async (key: string): Promise<void> => {
    const loadingId = 'login';
    startLoading(loadingId);
    try {
      const exists = await keyExists(key);
      if (exists) {
        setAPIKey(key);
      } else {
        setToast({
          message: 'Could not find API key',
          type: ToastType.ERROR,
        });
      }
    } catch (e) {
      console.error(`Could not login user`, e);
    }
    stopLoading(loadingId);
  };

  const generateLogin = () => {
    let key = '';
    return (
      <div className='Login'>
        <h3>Login</h3>
        <span>Please enter your API key to continue.</span>
        <Form.Control
          onKeyUp={async (event) => {
            if (event.key.toLowerCase() === 'enter') {
              await login(key);
            }
          }}
          onChange={(event: any) => {
            key = event.target.value;
          }}
        ></Form.Control>
        <Button variant='primary' onClick={() => login(key)}>
          Login
        </Button>
      </div>
    );
  };

  return (
    <div className='App'>
      <div className='Sidebar'></div>
      <div className='Map'>
        {' '}
        {apiKey ? generatePanels() : generateLogin()}
        <Map></Map>
        <div className='Toast'>
          <Toasts toast={toast}></Toasts>
        </div>
        {logs.length > 0 ? (
          <div className='PanelContent Logs'>
            <Logs job={jobInfo} logs={logs}></Logs>
          </div>
        ) : (
          ''
        )}
        {loading ? <ProgressBar animated now={100} label={loadingMessage}></ProgressBar> : <></>}
      </div>
    </div>
  );
}

export default App;
