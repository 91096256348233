// Integrate changes/fixes from https://github.com/geotiffjs/geotiff.js/pull/303 until released/integrated by geotiff.js
import { GeoTIFFImage } from 'geotiff';
import colormap from 'colormap';

export const getColorStops = (
  name: string,
  min: number,
  max: number,
  steps: number,
  reverse: boolean,
): any[] => {
  const delta = (max - min) / (steps - 1);
  const stops = new Array(steps * 2);
  const colors = colormap({ colormap: name, nshades: steps, format: 'rgba' });

  if (reverse) {
    colors.reverse();
  }
  for (let i = 0; i < steps; i++) {
    stops[i * 2] = min + i * delta;
    stops[i * 2 + 1] = colors[i];
  }
  return stops;
};
export const setupGeoTiff = () => {
  GeoTIFFImage.prototype.getSampleByteSize = function (i) {
    if (!this.fileDirectory.BitsPerSample || this.fileDirectory.BitsPerSample.length === 0) {
      return 0;
    }
    if (i >= this.fileDirectory.BitsPerSample.length) {
      i = 0;
    }
    return Math.ceil(this.fileDirectory.BitsPerSample[i] / 8);
  };

  GeoTIFFImage.prototype.getReaderForSample = function (sampleIndex) {
    const format = this.getSampleFormat(sampleIndex);
    const bitsPerSample = this.getBitsPerSample(sampleIndex);
    switch (format) {
      case 1: // unsigned integer data
        if (bitsPerSample <= 8) {
          return DataView.prototype.getUint8;
        } else if (bitsPerSample <= 16) {
          return DataView.prototype.getUint16;
        } else if (bitsPerSample <= 32) {
          return DataView.prototype.getUint32;
        }
        break;
      case 2: // twos complement signed integer data
        if (bitsPerSample <= 8) {
          return DataView.prototype.getInt8;
        } else if (bitsPerSample <= 16) {
          return DataView.prototype.getInt16;
        } else if (bitsPerSample <= 32) {
          return DataView.prototype.getInt32;
        }
        break;
      case 3:
        switch (bitsPerSample) {
          case 16:
            return function (offset, littleEndian) {
              // @ts-ignore
              return getFloat16(this, offset, littleEndian);
            };
          case 32:
            return DataView.prototype.getFloat32;
          case 64:
            return DataView.prototype.getFloat64;
          default:
            break;
        }
        break;
      default:
        break;
    }
    throw Error('Unsupported data format/bitsPerSample');
  };

  GeoTIFFImage.prototype.getSampleFormat = function (sampleIndex = 0) {
    if (!this.fileDirectory.SampleFormat || this.fileDirectory.SampleFormat.length === 0) {
      return 1;
    }
    return typeof this.fileDirectory.SampleFormat[sampleIndex] !== 'undefined'
      ? this.fileDirectory.SampleFormat[sampleIndex]
      : this.fileDirectory.SampleFormat[0];
  };

  GeoTIFFImage.prototype.getBitsPerSample = function (sampleIndex = 0) {
    if (!this.fileDirectory.BitsPerSample || this.fileDirectory.BitsPerSample.length === 0) {
      return;
    }
    return typeof this.fileDirectory.BitsPerSample[sampleIndex] !== 'undefined'
      ? this.fileDirectory.BitsPerSample[sampleIndex]
      : this.fileDirectory.BitsPerSample[0];
  };
};

// export const loadGeoTiff = async (url: string): Promise<GeoTiffInfo> => {
//   const response = await fetch(url, {
//     headers: {
//       'Content-Type': 'image/tiff; application=geotiff',
//     },
//   });
//
//   if (!response.ok) {
//     throw new Error(`Could not retrieve job result url ${url}: ${await response.text()}`);
//   }
//
//   console.log(url);
//
//   const blob = await response.blob();
//   const tiff = await GeoTIFF.fromBlob(blob);
//   const image = await tiff.getImage();
//   const bounds = image.getBoundingBox();
//
//   const dataUrl: string = await new Promise((resolve, reject) => {
//     const reader = new FileReader();
//     // @ts-ignore
//     reader.onload = (result) => {
//       const value = result.target?.result?.toString();
//       if (value) {
//         resolve(value);
//       } else {
//         reject('Could not decode image');
//       }
//     };
//     reader.readAsDataURL(blob);
//   });
//
//   return {
//     ,
//     bbox: bounds,
//     noData: image.getGDALNoData(),
//   };
// };
