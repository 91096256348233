import { create } from 'zustand';
import { computed } from 'zustand-computed';
import { OpenEOProcess, OpenEOProcessParam } from '../interfaces/OpenEOProcess';

interface ProcessBuilderStore {
  jobTitle: string | undefined;
  process: OpenEOProcess | undefined;
  params: OpenEOProcessParam[];
  setProcess: (process: OpenEOProcess | undefined) => void;
  setParamValue: (param: OpenEOProcessParam, value: any) => void;
  setJobTitle: (title: string) => void;
}
export interface ComputedProcessBuilderStore {
  valid: boolean;
}

export const useProcessBuilderStore = create<ProcessBuilderStore>()(
  computed(
    (set) => ({
      jobTitle: undefined,
      process: undefined,
      params: [],
      setProcess: (process: OpenEOProcess | undefined) =>
        set((state) => ({
          process,
          params: process?.parameters.map((p: OpenEOProcessParam) => ({
            ...p,
            value: undefined,
          })),
        })),
      setParamValue: (param: OpenEOProcessParam, value: any) =>
        set((state) => ({
          params: state.params.map((p: OpenEOProcessParam) => ({
            ...p,
            value: param.name === p.name ? value : p.value,
          })),
        })),
      setJobTitle: (title: string) =>
        set((state) => ({
          jobTitle: title,
        })),
    }),
    (state: ProcessBuilderStore): ComputedProcessBuilderStore => ({
      valid:
        !state.params || !state.params.find((p: OpenEOProcessParam) => !p.optional && !p.value),
    }),
  ),
);
