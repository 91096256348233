import { OpenEOProcess, OpenEOProcessParam } from '../../interfaces/OpenEOProcess';
import styles from './ProcessBuilder.module.css';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { ghcolors } from 'react-syntax-highlighter/dist/esm/styles/prism';
import NumberParam from './Params/NumberParam/NumberParam';
import IntervalParam from './Params/IntervalParam/IntervalParam';
import GeometryParam from './Params/GeometryParam/GeometryParam';
import StringParam from './Params/StringParam/StringParam';
import DataCubeParam from './Params/DataCubeParam/DataCubeParam';
import { OpenEOCollection } from '../../interfaces/OpenEOCollection';
import { useProcessBuilderStore } from '../../stores/processbuilder.store';
import { DataCubeValue } from './Params/DataCubeParam/DataCubeParam.model';
import { Polygon } from 'geojson';
import { executeProcess } from '../../utils/Api';
import { useUiStore } from '../../stores/ui.store';
import { ToastType } from '../toasts/Toast.model';
import BooleanParam from './Params/BooleanParam/BooleanParam';
import SelectParam from './Params/SelectParam/SelectParam';

interface ProcessBuilderProps {
  apiKey: string;
  processes: OpenEOProcess[];
  collections: OpenEOCollection[];
  serviceExecuted: (id: string) => void;
}

enum ProcessMode {
  SELECT,
  EXECUTE,
}

const renderProcessSelect = (s: OpenEOProcess) => (
  <option value={s.id} key={s.id}>
    {s.label}
  </option>
);

const updateSelected = (event: any, services: OpenEOProcess[], setSelected: Function) => {
  const service = services.find((s: OpenEOProcess) => s.id === event.target.value);
  setSelected(service);
};

const renderServiceInfo = (s: OpenEOProcess) => (
  <div className={styles.ServiceInfo}>
    <div className={styles.Description}>
      <Markdown
        remarkPlugins={[remarkGfm]}
        components={{
          img(props) {
            const { alt, src, title } = props;
            return (
              <img
                alt={alt}
                src={src}
                title={title}
                style={{
                  width: '100%',
                }}
              />
            );
          },
          code(props) {
            const { children, className, node, ...rest } = props;
            const match = /language-(\w+)/.exec(className || '');
            return match ? (
              <SyntaxHighlighter language={match[1]} style={ghcolors} PreTag='div'>
                {String(children).replace(/\n$/, '')}
              </SyntaxHighlighter>
            ) : (
              <code {...rest} className={className}>
                {children}
              </code>
            );
          },
        }}
      >
        {s.description}
      </Markdown>
    </div>
  </div>
);

const renderJobTitle = (jobTitle: string | undefined, setJobTitle: Function) => (
  <div className={styles.Param}>
    <div className={styles.ParamHeader}>
      <div className={styles.Title}>Job title</div>
    </div>
    <div className={styles.ParamInput}>
      <Form.Control
        value={jobTitle}
        onChange={(event: any) => {
          const text = event.target.value;
          if (text) {
            setJobTitle(text);
          }
        }}
      ></Form.Control>
    </div>
  </div>
);

const renderParam = (
  p: OpenEOProcessParam,
  collections: OpenEOCollection[],
  areaLimit: number,
  setParamValue: Function,
) => (
  <div className={styles.Param}>
    <div className={styles.ParamHeader}>
      <div className={styles.Title}>
        {p.name}
        {p.optional ? '' : '*'}
      </div>
      <div>{p.description}</div>
    </div>
    <div className={styles.ParamInput}>
      {p.schema.type === 'object' && p.schema.subtype === 'raster-cube' ? (
        <DataCubeParam
          collections={collections}
          areaLimit={areaLimit}
          setValue={(cube: DataCubeValue) => setParamValue(p, cube)}
        ></DataCubeParam>
      ) : (
        ''
      )}
      {p.schema.subtype === 'temporal-interval' ? (
        <IntervalParam
          value={p.value}
          setValue={(interval: any) => setParamValue(p, interval)}
        ></IntervalParam>
      ) : (
        ''
      )}
      {p.schema.type === 'object' && p.schema.subtype !== 'raster-cube' ? (
        <GeometryParam
          type='polygon'
          areaLimit={areaLimit}
          setValue={(feature: Polygon) => setParamValue(p, feature)}
        ></GeometryParam>
      ) : (
        ''
      )}
      {p.schema.type === 'string' ? (
        p.schema.enum && p.schema.enum.length > 0 ? (
          <SelectParam
            default={p.default}
            options={p.schema.enum}
            setValue={(text: any) => setParamValue(p, text)}
          ></SelectParam>
        ) : (
          <StringParam
            default={p.default}
            value={p.value}
            setValue={(text: any) => setParamValue(p, text)}
          ></StringParam>
        )
      ) : (
        ''
      )}
      {p.schema.type === 'number' ? (
        <NumberParam
          default={p.default}
          value={p.value}
          setValue={(text: any) => setParamValue(p, text)}
        ></NumberParam>
      ) : (
        ''
      )}
      {p.schema.type === 'boolean' ? (
        <BooleanParam
          value={p.value}
          default={p.default}
          name={p.name}
          setValue={(text: any) => setParamValue(p, text)}
        ></BooleanParam>
      ) : (
        ''
      )}
    </div>
  </div>
);

const renderServiceSelect = (
  processes: OpenEOProcess[],
  selected: OpenEOProcess | undefined,
  setSelected: Function,
  setMode: Function,
) => (
  <div className={styles.ServiceContainer}>
    <div className={styles.ServiceSelect}>
      <Form.Select onChange={(event: any) => updateSelected(event, processes, setSelected)}>
        <option selected disabled key='none'>
          Select a process
        </option>
        {processes.map((s: OpenEOProcess) => renderProcessSelect(s))}
      </Form.Select>
      {selected ? (
        <Button variant='primary' onClick={() => setMode(ProcessMode.EXECUTE)}>
          Execute
        </Button>
      ) : (
        <></>
      )}
    </div>
    {selected ? renderServiceInfo(selected) : ''}
  </div>
);

const renderServiceExecute = (
  selected: OpenEOProcess | undefined,
  collections: OpenEOCollection[],
  setMode: Function,
  valid: boolean,
  setParamValue: Function,
  execute: Function,
  loading: boolean,
  jobTitle: string | undefined,
  setJobTitle: Function,
) => (
  <div className={styles.ServiceContainer}>
    <div>
      <div className={styles.NavBack} onClick={() => setMode(ProcessMode.SELECT)}>
        <i className='bi bi-arrow-left-short'></i>
        <span>Go back to service selection </span>
      </div>
    </div>
    <h1>Execute - {selected?.id}</h1>
    {renderJobTitle(jobTitle, setJobTitle)}
    {selected
      ? selected.parameters.map((p: OpenEOProcessParam) =>
          renderParam(p, collections, selected.areaLimit, setParamValue),
        )
      : ''}
    <div className={styles.Actions}>
      <Button variant='primary' disabled={!valid || loading} onClick={() => execute()}>
        Execute {selected?.id}
      </Button>
    </div>
  </div>
);

function ProcessBuilder({ apiKey, processes, collections, serviceExecuted }: ProcessBuilderProps) {
  const [selected, setSelected] = useState<OpenEOProcess>();
  const [mode, setMode] = useState<ProcessMode>(ProcessMode.SELECT);
  const { valid, setProcess, process, params, setParamValue, jobTitle, setJobTitle } =
    useProcessBuilderStore();
  const { setToast, loadingIds, startLoading, stopLoading } = useUiStore();
  const loadingId = 'execute';

  const execute = () => {
    if (process) {
      startLoading(loadingId);
      executeProcess(apiKey, process.id, process.namespace, params, jobTitle)
        .then((id: string) => {
          serviceExecuted(id);
        })
        .catch((e) => {
          console.error(
            `Could not execute process ${process.id} from namespace ${process.namespace}`,
            e,
          );
          setToast({
            type: ToastType.ERROR,
            message: `Could not execute ${process.id} service`,
          });
        })
        .finally(() => {
          stopLoading(loadingId);
        });
    }
  };
  useEffect(() => {
    if (selected) {
      setJobTitle(`FuseTS - ${selected.label}`);
    }
    setProcess(selected);
  }, [selected]);

  return (
    <div className='Container'>
      {mode === ProcessMode.SELECT
        ? renderServiceSelect(processes, selected, setSelected, setMode)
        : renderServiceExecute(
            selected,
            collections,
            setMode,
            valid,
            setParamValue,
            execute,
            loadingIds.includes(loadingId),
            jobTitle,
            setJobTitle,
          )}
    </div>
  );
}

export default ProcessBuilder;
