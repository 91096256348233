import React, { useEffect, useState } from 'react';
import 'react-datetime/css/react-datetime.css';
import CollectionParam from '../CollectionParam/CollectionParam';
import GeometryParam from '../GeometryParam/GeometryParam';
import { Polygon } from 'geojson';
import styles from './DataCubeParam.module.css';
import IntervalParam from '../IntervalParam/IntervalParam';
import { DataCubeParamProps } from './DataCubeParam.model';

const DateCubeParam = ({
  collections,
  maxCollections,
  setValue,
  areaLimit,
}: DataCubeParamProps) => {
  const [collection, setCollection] = useState<string>();
  const [geometry, setGeometry] = useState<Polygon>();
  const [dates, setDates] = useState<[string, string]>();

  useEffect(() => {
    if (collection && geometry && dates) {
      setValue({
        collection,
        geometry,
        dates,
      });
    }
  }, [collection, geometry, dates]);

  return (
    <div className={styles.ParamList}>
      <div className={styles.Param}>
        <div className={styles.ParamTitle}>Area of interest</div>
        <GeometryParam
          areaLimit={areaLimit}
          type={'polygon'}
          setValue={setGeometry}
        ></GeometryParam>
      </div>
      <div className={styles.Param}>
        <div className={styles.ParamTitle}>Time of interest</div>
        <IntervalParam setValue={setDates}></IntervalParam>
      </div>
      <div className={styles.Param}>
        <div className={styles.ParamTitle}>Collection</div>
        <CollectionParam
          collections={collections}
          maxCollections={maxCollections}
          setValue={setCollection}
        ></CollectionParam>
      </div>
    </div>
  );
};

export default DateCubeParam;
