import React, { useEffect, useRef, useState } from 'react';
import { useMapStore } from '../../stores/map.store';
import { OpenEOJobResult } from '../../interfaces/OpenEOProcess';
import olMap from 'ol/Map.js';
import View from 'ol/View.js';
import TileLayer from 'ol/layer/WebGLTile';
import GeoTIFF from 'ol/source/GeoTIFF';
import { register } from 'ol/proj/proj4.js';
import 'ol/ol.css';

import proj4 from 'proj4';
import { getColorStops } from '../../utils/Geotiff';
import { Form } from 'react-bootstrap';
import styles from './Map.module.css';
import { XYZ } from 'ol/source';
import VectorSource from 'ol/source/Vector';
import VectorLayer from 'ol/layer/Vector';
import Draw from 'ol/interaction/Draw.js';
import Modify from 'ol/interaction/Modify.js';
import GeoJSON from 'ol/format/GeoJSON';
import Feature from 'ol/Feature';
import { defaults } from 'ol/interaction/defaults';
import { useUiStore } from '../../stores/ui.store';

proj4.defs('EPSG:32631', '+proj=utm +zone=31 +datum=WGS84 +units=m +no_defs +type=crs');

register(proj4);

function Map() {
  const mapProjection = 'EPSG:3857';
  const mapContainer = useRef(null);
  const [map, setMap] = useState<olMap>();
  const { addFeatures, updateFeatures, features, enableDraw, jobResult } = useMapStore();
  const { startLoading, stopLoading } = useUiStore();
  const [jobLayers, setJobLayers] = useState<{ id: string; layer: TileLayer }[]>([]);
  const [legend, setLegend] = useState<any[]>([]);
  const [activeResult, setActiveResult] = useState<OpenEOJobResult>();
  const [featureSource, setFeatureSource] = useState<VectorSource>();
  const [drawInteraction, setDrawInteraction] = useState<Draw>();
  const [opacity, setOpacity] = useState<number>(100);
  const [legendExtremes, setLegendExtremes] = useState<[number | null, number | null]>();
  const [activeBand, setActiveBand] = useState<number>(0);
  const [tilesLoading, setTilesLoading] = useState<number>(0);

  useEffect(() => {
    // @ts-ignore
    if (mapContainer.current && mapContainer.current.children.length > 0) return;

    const vectorSource = new VectorSource({ wrapX: false });
    const draw = new Draw({
      source: vectorSource,
      type: 'Polygon',
    });
    const modify = new Modify({
      source: vectorSource,
    });

    draw.on('drawend', (event) => {
      event.feature.setId(`feature_${vectorSource.getFeatures().length + 1}`);
    });

    vectorSource.on('addfeature', (event) => {
      if (event.feature) {
        addFeatures([formatFeature(event.feature)]);
      }
    });

    modify.on('modifyend', (event) => {
      const feature = formatFeature(event.features.item(0));
      updateFeatures([feature]);
    });

    const formatFeature = (feature: Feature): any => {
      const geojsonFormat = new GeoJSON();
      return geojsonFormat.writeFeatureObject(feature, {
        dataProjection: 'EPSG:4326',
        featureProjection: mapProjection,
      });
    };

    setMap(
      new olMap({
        target: 'map',
        layers: [
          new TileLayer({
            source: new XYZ({
              url: `https://api.mapbox.com/styles/v1/mapbox/satellite-streets-v12/tiles/{z}/{x}/{y}?access_token=${process.env.REACT_APP_MAPBOX_TOKEN}`,
              tileSize: 256,
            }),
          }),
          new VectorLayer({
            source: vectorSource,
          }),
        ],
        interactions: [...defaults().getArray(), modify],
        view: new View({
          projection: mapProjection,
          center: [0, 0],
          zoom: 1,
        }),
      }),
    );
    setFeatureSource(vectorSource);
    setDrawInteraction(draw);
  }, []);

  useEffect(() => {
    if (tilesLoading > 0) {
      startLoading('load_tiles');
    } else {
      stopLoading('load_tiles');
    }
  }, [tilesLoading]);

  useEffect(() => {
    if (map) {
      if (jobResult && jobResult.results.length > 0) {
        setActiveResult(jobResult.results[0]);
      } else {
        hidePrevious();
      }
    }
  }, [jobResult]);

  useEffect(() => {
    if (activeResult) {
      showJob(activeResult);
    }
  }, [activeResult]);

  useEffect(() => {
    jobLayers.forEach(({ layer }) => {
      layer.setOpacity(opacity / 100);
    });
  }, [opacity]);

  useEffect(() => {
    const activeIds = features.map((f) => f.id);
    const featuresToRemove =
      featureSource?.getFeatures().filter((f) => !activeIds.includes(f.getId())) || [];
    featuresToRemove.forEach((f) => featureSource?.removeFeature(f));
  }, [features]);

  const showJob = (result: OpenEOJobResult) => {
    const layers: { id: string; layer: TileLayer }[] = [];

    hidePrevious();
    setTilesLoading(0);

    const layer = createGeoTiffLayers(result);
    map?.addLayer(layer);
    layers.push({
      id: getJobLayerId(result),
      layer,
    });
    setJobLayers(layers);
    if (result.bbox) {
      map?.getView().fit(result.bbox);
    }
  };

  const getJobLayerId = (result: OpenEOJobResult) => {
    return result.name;
  };

  const hidePrevious = () => {
    jobLayers.forEach(({ layer }) => {
      map?.removeLayer(layer);
    });
  };

  const createGeoTiffLayers = (result: OpenEOJobResult): TileLayer => {
    const layer = new TileLayer({
      source: new GeoTIFF({
        projection: `epsg:${result.epsg}`,
        interpolate: false,
        normalize: false,
        sources: [
          {
            url: result.url,
            nodata: 0,
          },
        ],
        sourceOptions: {
          cacheSize: 2048,
        },
      }),
      style: getGeoTiffStyle(result, 0),
      cacheSize: 2048,
    });

    (layer.getSource() as GeoTIFF).on('tileloadstart', () => setTilesLoading(tilesLoading + 1));
    (layer.getSource() as GeoTIFF).on('tileloadend', () => setTilesLoading(tilesLoading - 1));
    return layer;
  };
  const getGeoTiffStyle = (
    result: OpenEOJobResult,
    bandIdx: number,
    legendExtremes?: [number | null, number | null],
  ) => {
    const band = ['band', bandIdx + 1];
    const min = ['var', `min`];
    const max = ['var', `max`];

    const legendMin =
      legendExtremes?.[0] == null ? result.bands[bandIdx].statistics.minimum : legendExtremes?.[0];
    const legendMax =
      legendExtremes?.[1] == null ? result.bands[bandIdx].statistics.maximum : legendExtremes?.[1];

    const legend = getColorStops('viridis', legendMin, legendMax, 10, true);

    setLegend(legend);

    const interpolate = ['interpolate', ['linear'], band, ...legend];

    const checkAboveMinimum = ['>=', band, min];
    const checkBelowMaximum = ['<=', band, max];
    const checkInRage = ['all', checkAboveMinimum, checkBelowMaximum];

    return {
      color: ['case', checkInRage, interpolate, [0, 0, 0, 0]],
      variables: getVariables(result, bandIdx, legendMin, legendMax),
    };
  };

  const getVariables = (result: OpenEOJobResult, bandIdx: number, min: number, max: number) => ({
    band: bandIdx + 1,
    min: min,
    max: max,
    alphaband: result.bands.length + 1,
  });

  const updateGeoTiffStyle = (
    result: OpenEOJobResult,
    bandIdx: number,
    legendExtremes?: [number | null, number | null],
  ) => {
    const layer = jobLayers.find(({ id }) => id == getJobLayerId(result));
    if (layer) {
      layer.layer.setStyle(getGeoTiffStyle(result, bandIdx, legendExtremes));
    }
  };

  useEffect(() => {
    if (drawInteraction) {
      if (!enableDraw) {
        map?.removeInteraction(drawInteraction);
      } else {
        map?.addInteraction(drawInteraction);
      }
    }
  }, [enableDraw]);

  useEffect(() => {
    if (activeResult && activeBand !== null) {
      updateGeoTiffStyle(activeResult, activeBand, legendExtremes);
    }
  }, [activeBand, legendExtremes]);

  const generateLegend = () => {
    const items = [];
    const min = legend[0];
    const max = legend[legend.length - 2];
    for (let i = 0; i < legend.length; i += 2) {
      items.push({
        value: legend[i],
        color: `rgba(${legend[i + 1].join(',')})`,
      });
    }

    const setExtremes = (nMin: number | null, nMax: number | null) => {
      if (nMin !== min || nMax !== max) {
        setLegendExtremes([nMin, nMax]);
      }
    };

    return (
      <>
        <div className={styles.LegendHeader}>
          <Form.Group>
            <span>Min</span>
            <Form.Control
              type='number'
              aria-label='Min'
              onChange={(event: any) =>
                setExtremes(event.target.value === '' ? null : +event.target.value, max)
              }
            ></Form.Control>
          </Form.Group>
          <Form.Group>
            <span>Max</span>
            <Form.Control
              type='number'
              onChange={(event: any) =>
                setExtremes(min, event.target.value === '' ? null : +event.target.value)
              }
            ></Form.Control>
          </Form.Group>
        </div>
        <div className={styles.Legend}>
          {items.map((item, idx: number) => (
            <div key={`legend_${idx}`} className={styles.LegendItem}>
              <div className={styles.LegendColor} style={{ backgroundColor: item.color }}></div>
              <div className={styles.LegendLabel}>{item.value.toFixed(2)}</div>
            </div>
          ))}
        </div>
      </>
    );
  };

  return (
    <>
      <div
        ref={mapContainer}
        id='map'
        className='map-container'
        style={{
          width: '100%',
          height: '100%',
        }}
      ></div>
      {jobResult && jobResult.results.length > 0 ? (
        <div className={styles.Panel}>
          <div className={styles.ResultSelect}>
            <Form.Select
              onChange={(event) => setActiveResult(jobResult.results[+event.target.value])}
            >
              {jobResult.results.map((j: OpenEOJobResult, idx: number) => (
                <option value={idx} key={`jobresult_${idx}`}>
                  {j.name}
                </option>
              ))}
            </Form.Select>
          </div>
          {activeResult ? (
            <div>
              <Form.Select onChange={(event) => setActiveBand(+event.target.value)}>
                {activeResult.bands.map((b, idx) => (
                  <option value={idx} key={activeResult.name + b.name}>
                    {b.name}
                  </option>
                ))}
              </Form.Select>
              {legend.length > 0 && legend.length % 2 === 0 ? generateLegend() : <></>}
              <>
                <Form.Label>Opacity</Form.Label>
                <Form.Range value={opacity} onChange={(event) => setOpacity(+event.target.value)} />
              </>
            </div>
          ) : (
            <></>
          )}
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default Map;
